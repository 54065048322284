.cursor-pointer {
  cursor: pointer;
}

/* Topbar */

.topbar {
  position: sticky;
  background: #fff;
  top: 0;
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 36px;
  box-shadow: 0 0 9px 0 rgba(160, 160, 160, 0.5);
  color:#402c87;
  z-index: 9999;
}

.navToggleSpecial {
  display: none;
}

.topbar-nav {
  display: flex;
  align-items: center;
}

.navToggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  display: none;
}

.topbar-name {
  color: var(--color_p2);
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
}

.topbar-name-mobile {
  display: none;
}

.topbar-icons {
  color:#402c87;
  cursor: pointer;
  width: 45px;
  height: 45px;
  padding: 10px;
}

.topbar-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50px; */
  height: 50px;
  margin-left: 20px;
  padding-left: 10px;
  border-bottom: 3px solid #fff;
}

.topbar-icons-container:hover {
  /* color: var(--color_p2); */
  color:#402c87;
  border-bottom: 3px solid var(--color_p2);
  transition: 0.1s;
}

.nav_active {
  color: var(--color_p2);
  border-bottom: 3px solid var(--color_p2);
  transition: 0.1s;
}

.topbar-icons-container:hover i {
  color: var(--color_p2);
  transition: 0.2s;
}

.topbar-profile:hover i {
  color: lightgray;
}

.topbar-profile {
  background-color: var(--color_p4);
  box-shadow: 0 0 3px rgb(200, 200, 200);
}

.top-profile-name {
  margin-left: 10px;
}

/* Searchbar */
.searchBox {
  position: relative;
}

.searchbar {
  margin: 30px 0;
}

.search-input {
  position: relative;
  display: flex;
  width: 100%;
}

.search-input button {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  padding: 0px 50px;
  margin: 5px 0;
  background-color: var(--color_p2);
  color: #fff;
  height: 40px;
}

.search-input button:hover {
  background-color: var(--color_p4);
  color: #fff;
}

.searchDropdown {
  position: absolute;
  width: 100%;
  top: 80%;
  z-index: 999;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px rgba(112, 112, 112, 0.32);
  background-color: #fff;
}

.searchDropdown div {
  padding: 5px 10px;
  font-size: 14px;
  color: #636363;
}

/* Cards */
.grid-wrapper {
  /* --auto-grid-min-size: 14rem; */
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 170px);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.grid-wrapper .child_cards * {
  margin: 0;
}

.grid-wrapper .child_cards {
  list-style-type: none;
}

.grid-wrapper .child_cards a {
  width: 100%;
  min-height: 170px;
  position: relative;
  font-size: 24px;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
  padding: 20px 15px 10px;
  border-bottom: 3px solid var(--color_p2);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.talent-heading {
  grid-area: 1/1/2/5;
  align-items: start;
}

.side_div {
  list-style: none;
  grid-area: 1/4 / 4/ 5;
  box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
  padding: 19px 14px;
  /* height: 400px; */
  position: relative;
  /* top: -12%; */
}

.side_div .sd_1 {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.side_div .sd_1 * {
  margin: 2px 0;
}

.side_div .sd_1 img {
  width: 120px;
  height: 112px;
}

.side_div .sd_1 .sd_upper div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side_div .sd_1 .sd_upper div h6 {
  font-size: 14px;
  font-weight: 300;
}

.side_div .sd_1 .sd_upper div span {
  font-size: 14px;
  font-weight: 300;
  color: #b8e986;
}

.side_div .sd_1 .line {
  width: 231px;
  height: 6px;
  background-color: #8443e5;
  border-radius: 6.5px;
}

.side_div .sd_lower {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.side_div .sd_lower div {
  color: #959595;
  font-size: 15px;
  width: 248px;
  height: 30px;
  padding: 13px 12.5px 12px 15px;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.side_div .sd_lower div span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.side_div .sd_lower div svg {
  margin-right: 7px;
  color: #b8e986;
}

.card-desc {
  font-size: 14px;
  color: #a3a3a3;
  margin-bottom: 20px;
  overflow-wrap: anywhere;
}

.posted-by {
  font-size: 14px;
  color: #a3a3a3;
}

.card-author {
  color: var(--color_p2);
  font-size: 16px;
}

.card-footer {
  color: var(--color_p2);
  font-size: 14px;
  margin-top: 10px;
}

.card-apply {
  display: none;
  position: absolute;
  right: 10px;
  bottom: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  padding: 5px 30px;
  border-radius: 5px;
  background-color: var(--color_p3);
  color: #fff;
}

.grid-wrapper .child_cards a:hover .card-apply {
  display: block;
  background-color: var(--color_p3);
  color: #fff;
  transition: 0.3s;
}

.grid-wrapper .child_cards a:hover {
  background-color: var(--color_p2);
  transition: 0.3s;
  color: #fff;
}

.grid-wrapper .child_cards a:hover .card-footer,
.grid-wrapper .child_cards a:hover .card-author,
.grid-wrapper .child_cards a:hover .card-desc,
.grid-wrapper .child_cards a:hover .card_date * {
  color: #fff;
}

.card_date h6 {
  font-size: 12px;
  color: #444;
}

.card_date p {
  font-size: 12px;
  color: #8443e5;
}

/* Topbar profile options */
div.profile-options {
  position: absolute;
  top: 100%;
  max-height: fit-content;
  box-shadow: 0 8px 9px 0 rgba(160, 160, 160, 0.5);
  background-color: #fff;
  height: 0px;
  overflow-y: hidden;
  transition: all 0.9s ease-in;
}

div.profile-options>ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: inherit;
  height: inherit;
}

div.profile-options>ul>li {
  height: 50px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  color: #8443e5;
}

div.profile-options>ul>li:last-child {
  border-bottom: none;
}

div .profile-options ul li a {
  display: block;
  padding: 20px 0px 0px 20px;
  width: 100%;
  height: 100%;
}

div .profile-options ul li a:hover {
  background-color: #f2edf8;
}

/* Sidebar */
.sidebar {
  position: fixed;
  left: 0;
  min-height: 100vh;
  width: 300px;
  padding: 50px 0;
  background-color: #402c87;
  z-index: 9999;
}

.sidebar-components {
  width: 250px;
  margin: auto;
}

.sidebar-components h3 {
  font-size: 25px;
  font-weight: 600;
  color: #8443e5;
  margin-left: 5px;
  margin-bottom: 50px;
}

.sidebar-items {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-left: 20px;
  color: white;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.active span {
  display: inline-block;
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: #8443e5;
  transition: 0.3s;
}

.active {
  color: #8443e5;
  border: 30px;
  transition: 0.3s;
}

/* Modal */
.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  padding: 28px;

  width: 658px;
  height: 434px;
  margin: 85px 16px 102px 5px;
  padding: 19px 19.8px 24px 24px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.modal-body::-webkit-scrollbar {
  display: none;
}

.modal-header {
  color: #ff8484;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}

.modal-body {
  border: solid 1px #ddd;
  padding: 15px 37px;
  margin: 10px !important;
  width: 603px;
  height: 275px;
  overflow-y: scroll;
}

.modal-name,
.role-name {
  color: var(--color_p2);
  font-weight: bold;
  font-size: 18px;
}

.total-roles button {
  width: 132px;
  height: 45px;
  padding: 14px 47px 13px;
  border-radius: 6px;
  font-size: 14px;
}

.role-name {
  width: 200px;
}

.char-name {
  color: var(--color_p2);
  font-size: 18px;

  font-weight: bold;
}

.modal-name {
  margin: 15px 0;
}

.modal-roles {
  margin-top: 20px;
}

.modal-roles>div:not(:first-child) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin: 27px 0 !important;
}

.apply-btn,
.cancel-btn {
  height: 50px;
  width: 150px;
  border: none;
  background: #e8e8e8;
  border-radius: 5px;
  font-size: 15px;
}

.update-btn {
  height: 50px;
  width: 150px;
  border: none;
  color: #fff;
  background: var(--color_p2);
  border-radius: 5px;
  font-size: 15px;
}

.modal-footer {
  margin-top: 15px;
  display: block;
  text-align: center;
}

div.lI {
  display: flex;
  flex-direction: row;
  align-items: center;

  /* margin-left: 60px; */
  margin-top: 30px;
}

div.lI div {
  width: 33%;
}

div.lI div img {
  width: 63px;
  height: 63px;
  border-radius: 5px;
  margin-right: 16px;
}

div.lI div span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #303030;
}

div.lI div.actionButtons button {
  width: 105px;
  background-color: #fff;
  margin-right: 18px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #6cc592;
  color: #6cc592;
}

div.lI div.actionButtons {
  display: flex;
  width: 30%;
  background-color: #fff;
  opacity: 1;
}

.Card-body {
  border: 1px solid black;
  padding: 1rem;
  border-radius: 0.5rem;
}

.char {
  cursor: pointer;
}

.charactersList:hover {
  background-color: #523aa8;
  color: white;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* ============ loader ===== */
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #8443e5;
  border-bottom: 16px solid #8443e5;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Channel Card */

.flexCont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* max-width: 34rem; */
  padding: 0px 0px 0px 20px;
}

.part>span {
  display: block;
}

.channelImg {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}

.new-style-channelImg {
  width: 100px;
  height: 100px;
}

span.channelTitle {
  display: inline-block;
  font-size: 20px;
  color: #8443e5;
  font-weight: bold;
}

span.country {
  display: inline;
}

.channelStats {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  font-size: 15px;
  flex-wrap: wrap;
  max-width: 23rem;
}

.channelStats>span {
  display: block;
  width: 147px;
}

.channelStats .likes svg {
  font-size: 17px;
}

/*-------------NEW Style---------------*/

.channel-details {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}

.channel-head p {
  width: 100%;
  margin: 0;
  padding: 0;
}

.channel-face {
  padding: 0px 0px 0px 20px;
}

.channel-name {
  font-size: 24px;
  font-weight: 600;
  color: #030303;
}

.sub-count {
  font-size: 14px;
  font-weight: 400;
  color: #606060;
}

.channel-location {
  font-weight: 16px;
  font-weight: 600;
  color: rgba(32, 32, 32, 0.7);
}

.channel-info-div {
  display: flex;
  /* width: 200px; */
  padding: 10px 0px;
}

.channel-div {
  display: flex;
  justify-content: space-between;
}

.channel-info-text {
  font-size: 16px;
  font-weight: 400;
}

.channel-img {
  width: 20px;
  margin-right: 10px;
}

.channel-topic {
  font-size: 16px;
  font-weight: 500;
  color: rgba(32, 32, 32, 0.7);
}