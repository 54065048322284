@media screen and (max-width: 468px) {

  .card-search-confirm {
    width: 100%;
  }

  .collabContainer {
    width: 100%;
    padding: 10px;
    overflow: hidden;
  }

  .mobile-overflow-hide {
    overflow-x: hidden;
  }

  .width-modal {
    width: 100%
  }

  .influncer-nomargin {
    margin-left: 0px;
  }

  .slot-input {
    width: 50%;
    border: none;
    border-radius: 0px 0px 0px 8px;
    padding: 0px 20px;
  }

  .width-pc-and-mobile {
    width: 70%;
    margin-left: 10px;
  }

  .collab-box {
    flex-wrap: wrap;
  }

  .Slot-Container {
    width: 100%;
    padding: 10px;
    overflow: hidden;
  }

  .width-100-mobile {
    width: 100vw;
  }

  .collab-type {
    max-width: 40%;
    padding: 5px
  }

  .mobile-wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 50vw;
  }

  .padding-left-and-right {
    /* padding-left: 0; */
    /* padding-right: 0; */

  }

  .margin-left-desktop {
    margin-left: 0;
  }

  /* 
  .aa_head p {
    width: fit;
  } */

  .image-profile {
    width: 100%;
    height: 100%;
  }

  .td-desktop-only {
    display: none;
  }

  .width-mobile {
    width: 100%;
  }


  .strength-main-div {
    display: flex;
    flex-direction: column;
  }

  .mobile-only {
    display: block;
  }

  .channelContainer {
    width: 100%
  }

  .padding-4 {
    padding: 0
  }

  .size-device {
    width: 100%;
    height: 100%;
  }

  .nav-item-name {
    display: flex;
  }

  .modal-main {
    width: 90vw;
    position: fixed;
    left: 5vw;
    /* top: 25%; */
    /* padding: 15px; */
    background: #ffffff;
  }

  .myChat input {
    padding-left: 30%;
  }

  .sticky-left {
    position: sticky;
    left: 0
  }

  .top-mobile-chat {
    position: fixed;
    z-index: 10;
  }

  .cb_msgSend {
    position: fixed;
    bottom: 0;
    padding-left: 0px;
  }

  .bottom-chat-mobile {
    margin-top: 60px;
  }

  .chatBox_main {
    /* margin-top: 30px; */
    /* height: auto; */
    bottom: 0
  }

  .influencers {
    padding-right: 0px;
    margin-right: 0px;
  }

  .buy-confirm {
    width: 80vw;
  }

  .btn-marked {
    display: none;
  }

  .btn-mobile-marked {
    display: block;
    width: fit-content;
    /* overflow: hidden; */
    /* background-color: #8443e5; */
    text-align: center;
    padding: .7rem .8rem;
    border-radius: .3rem;
    cursor: pointer;
    color: black;
  }

  .slotBook {
    width: 100%;
  }

  .topbar {
    position: sticky;
    height: fit-content;
    width: 100%;
    padding: 10px;
  }

  .account-main-div {
    width: 100%
  }

  .form-control {
    /* width: 80%; */
    margin-right: 5px;
  }

  .wrap-flex {
    flex-wrap: wrap;
  }

  .form-control option {
    /* width: 100%; */
    /* margin-right: 5px; */
  }

  .setting-textfield {
    margin-right: 5px;
    margin-bottom: 5px !important;
  }

  .custom-margin-right {
    margin-right: 5px;
  }

  .topbar,
  .topbar-nav {
    align-items: start;
    flex-direction: column;

  }

  .topbar-nav {
    width: 80%;
    display: none;
  }

  .topbar-nav {
    position: absolute;
    top: 100%;
    padding-right: 10px;
    /* width: 100%; */
    left: 0px;
    background-color: #fff;
    margin: 0;
    box-shadow: 0 2px 4px #aba8a8;
  }

  .topbar-icons-container {
    padding: 10px;
  }

  .topbar-name {
    position: relative;
    width: 100%;
    margin-left: 10px;
  }

  .navToggle {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }

  .navToggleSpecial {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -15px;
  }

  .create-btn {
    margin: 0;
  }

  .grid-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-template-rows: repeat(auto, 170px) !important;
  }

  .talent-heading {
    grid-area: 1/1/1/1 !important;
  }

  .side_div {
    /* display: none; */
    grid-area: 2/1/5/1 !important;
    top: 0;
  }

  .show-desktop {
    display: none;
  }

  .show-mobile {
    display: flex;
  }

  .show-mobile-btn {
    display: flex;
  }

  .side_div_two {
    /* display: grid; */
    grid-area: 2/1/5/1 !important;
    top: 0;
  }

  .side_div_mobile {
    display: grid;
    grid-area: 2/1/5/1 !important;
    top: 0;
    list-style: none;
    grid-area: 1/4 / 4/ 5;
    box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
    padding: 19px 14px;
    /* height: 400px; */
    position: relative;
    /* top: -12%; */

  }

  .grid-wrapper .child_cards a {
    height: 170px;
  }

  .notif-options {
    left: 0;
    right: auto;
    width: calc(100vw - 20px);
    z-index: 2;
  }

  div.profile-options {
    overflow-y: auto;
    right: auto;

  }

  .search-input button {
    padding: 0 15px;
  }

  .navSideToggle {
    display: block;
  }

  /* ===================browse profile========================= */
  .b_table thead td {
    font-size: 13px;
    /* width: 0; */
  }

  .b_table tbody tr td img {
    /* width: 20%; */
    height: auto;
    margin-right: 5px;
  }

  .b_table tbody tr td p {
    width: 100%;
    height: auto;
    /* margin-right: 5px; */
  }

  .b_table table tbody td {
    /* font-family: Muli; */
    font-size: 13px;
    padding: 2px;
    /* width: 20%; */
  }

  .margin-mobile {
    margin-bottom: 10px;
  }

  .scroll-container {
    overflow-x: auto;
    overflow-y: clip;
    white-space: nowrap;
    /* Prevent table cell content from wrapping */
    width: 100%;
  }

  .b_table table tbody td button {
    font-size: 8px;
    width: 100%;
  }

  /* ==========================chat box================================= */
  .cb_bottom {
    padding: 5px;
  }

  .chat_page {
    padding: 0px;
  }

  .cb_chats_msg img {
    width: 35px;
    height: 35px;
  }

  .cb_chats_msg p {
    padding: 10px 22px;
    width: auto;
    max-width: 80%;
    white-space: wrap;
  }

  .msg_Div {
    margin-bottom: 50px;
  }

  .cb_chats_msg2 img {
    width: 35px;
    height: 35px;
  }

  .cb_chats_msg2 {
    /* padding-left: 100px;
    width:80%; */
  }

  .cb_chats_msg2 p {
    padding: 10px 22px;
    width: auto;
    max-width: 80%;
    white-space: wrap;
  }

  /* ==========================applicant details=============================== */
  .content-container {
    flex-direction: column;
    padding: 10px;
  }

  .projCont {
    width: 100%;
    margin: auto;
  }

  div.applicantDetails {
    width: 100%;
    padding-left: 10px;
  }

  div.statusContainer {
    display: flex;
    margin-left: 0;
  }

  div.statusContainer>div {
    padding: 5px;
  }

  .applicantDetails .leadRoles {
    margin-top: 36.5px;
  }

  div.lI div.actionButtons {
    flex-direction: column;
  }

  div.lI div span {
    font-size: 12px;
  }

  .leadRoles span,
  div.statusContainer>div span {
    font-size: 10px;
  }

  .topNavbar span {
    white-space: nowrap;
    font-size: 12px;
  }

  .topNavbar span[highlighted="true"]::after {
    top: 30px;
  }

  .mt-1 {
    margin: 1rem;
  }

  /* ===============user profile========================================== */
  .horizontal_nav {
    position: relative;
  }

  .horizontal_nav span {
    font-size: 12px;
  }

  .userPfp {
    height: 100%;
    padding: 0px;
  }

  .right_pfp {
    margin-top: 20px;
    height: 100vh;
  }

  /* ==============================roles======================================= */

  .roleUpdate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0;
  }

  .flex-container {
    padding: 10px;
    flex-direction: column;
  }

  .roleChild .form-container {
    margin: 0;
  }

  .form-container {
    width: 100%
  }

  .apply-btn,
  .cancel-btn {
    width: 100%;
    margin: 0 !important;
  }

  .save-btn {
    width: 100%;
  }

  .toggle-option {
    margin-right: 70px;
  }

  .char-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .charList div {
    margin-right: 5px;
    width: auto;
  }

  .roleChild {
    width: 100%;
    min-height: 100%;
    padding: 10px 5px;
    margin: 10px 3px;
  }

  .form-body {
    height: auto;
    overflow-y: visible;
  }

  .form-toggle {
    padding: 0;
  }

  .charList {
    padding: 0;
  }

  .charList div {
    font-size: 10px;
    padding: 2px 7px;
  }

  .experience_container .ec_child div {
    padding: 5px;
  }

  .ec_child {
    width: 300px;
  }

  /* ==================================login====================================== */
  .login-container {
    height: auto;
    width: 100%;
  }

  .left-side {
    display: none;
  }

  /* ==============================side nav======================================= */
  .side_nav {
    width: 30%;
  }



  .right-side {
    width: 100%;
  }

  .navSideToggle {
    position: fixed;
    top: 35px;
    transform: translateY(-50%);
    font-size: 25px;
    right: 10px;
    color: #8443e5;
    z-index: 999999999999;
  }

  .subAud {
    width: 100%;
    margin-left: 0;
  }

  .aa_head {
    font-size: 12px;
  }

  /* ====================talent side========================== */
  .role_main {
    padding: 10px 10px;
  }

  .requestPage {
    padding: 0 10px;
  }

  .modal-container {
    width: 95%;
  }

  .modal-body {
    border: solid 1px #ddd;
    padding: 15px 15px;
    margin: 10px !important;
    width: 100%;
  }

  .modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .modal-footer button {
    width: 48%;
  }

  /* ===========================my roles========================= */
  .role_main .long_Cards {
    flex-direction: column;
    height: fit-content;
  }

  .role_main .long_Cards .firstDiv {
    width: 100%;
  }

  .role_main .long_Cards div {
    flex-direction: column;
  }

  .rCard_child button {
    width: 90%;
  }

  /* =======================profile details============================== */

  .talent_container {
    padding: 10px !important;
  }

  .side_toggle_chat {
    display: none;
  }

  .sidebar-toggle {
    display: none;
  }

  .side_chat {
    display: block !important;
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 9999;
    background-color: #fff;
    width: 0;
    height: calc(100%) !important;
  }

  .myChat_main {
    height: calc(100vh - 70px);
  }

  .myChat_users {
    height: calc(100vh - 130px);
  }

  .stylishImages {
    display: none;
  }

  .web1 {
    padding: 20px;
  }

  .web1-signin {
    margin: 0;
    height: calc(100vh - 40px) !important;
  }

  .accordion_left {
    display: none;
  }

  .notification {
    padding: 10px !important;
  }


  .buttons {
    flex-direction: column;
  }

  .menu-project-creation {
    display: flex;
  }

  .showinpc {
    display: none;
  }


  .influencers-main {
    display: flex;
    flex-direction: column;
  }

  /* .channel-head {
    display: flex;
    flex-direction: column;
  }

  .channel-details {
    display: flex;
    flex-direction: column;
  }

  .card-influencer {
    width: 100%
  } */

}

/* ============ editProfile ======== */

.editProfile {
  border: 1px solid #8443e5;
  color: #8443e5;
  opacity: 1;
  font-weight: 600;
  font-size: .8rem;
  border-radius: .2rem;
  padding: .3rem .8rem;
}

.editProfile:hover {
  color: #8443e5;
}




/* unstructured */
.modal-content {
  width: fit-content;
}

.verifyEmail {
  width: 100%
}

.btn-marked {
  background-color: #8443e5;
  text-align: center;
  padding: .7rem .8rem;
  border-radius: .3rem;
  cursor: pointer;
  color: white;
  /* padding: 2px; */
}