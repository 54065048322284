td {
  vertical-align: top;
  padding-bottom: 15px;
}

hr {
  height: 1px;
  color: #999;
  background-color: #999;
  margin: 30px 0;
}

select {
  color: #999 !important;
  background: transparent;
}

input[type="text"]::placeholder {
  color: #d9d9d9;
}

input[type="password"]::placeholder {
  color: #d9d9d9;
}

input {
  height: 50px;
}

.row > * {
  padding: 0;
}

.form-select {
  height: 50px;
}

.text-area {
  padding: 20px;
}

.empty {
  color: #999;
}

.form-body {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.form-container {
  margin: auto;
  padding: 20px;
  padding-bottom: 50px;
  width: 500px;
  display: flex;
  flex-direction: column;
  background: #f8f3ff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
}

.edit-youtube-heading {
  color: #030303;
  font-size: 20px;
}

.formChild {
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* height: 190px; */
}
.form-head {
  color: #6c54a6;
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 5px;
}
.formChild::-webkit-scrollbar {
  display: none;
}
.form-desc {
  color: #d9d9d9;
  color: black;
  margin-bottom: 30px;
}

.label-desc {
  color: #aeaab4;
  margin: 10px 5px;
}

.form-summary {
  color: #444444;
  overflow-wrap: break-word;
  /* margin-bottom: 30px; */
  max-width: 100%;
  overflow-wrap: break-word;

}

.form-control {
  margin-bottom: 10px;
}

.save-btn {
  background-color: var(--color_p2);
  border: var(--color_p2) 2px solid;
  /* width: 273px; */
  height: 50px;
  color: #fff;
  border-radius: 5px;
}

.save-btn:hover {
  background-color: #fff;
  border: var(--color_p2) 2px solid;
  color: var(--color_p2);
}

.cancel-btn {
  background-color: #aeaab4;
  border: #aeaab4 2px solid;
  /* width: 152px; */
  height: 50px;
  color: #fff;
  border-radius: 5px;
  /* margin-right: 28px !important; */
}

.cancel-btn:hover {
  background-color: #fff;
  border: #aeaab4 2px solid;
  color: #aeaab4;
}

.form-toggle {
  padding: 0px 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  overflow-x: auto;
}
.form-toggle::-webkit-scrollbar {
  display: none;
}

.toggle-option {
  /* min-width: 50%; */
  padding: 7px 10px;
  color: #d8d8d8;
  font-weight: bold;
  cursor: pointer;
  margin-right: 103px;
  font-size: 16px;
  /* width: 150px; */
}

.active-toggle {
  color: #6c54a6;
  border-bottom: 4px solid #8443e5;
}

.small-text {
  color: #444444;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.full-width-btn {
  color: #fff;
  background-color: #6c54a6;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 3px;
  padding: 0 20px;
  margin: 10px 0 20px;
}

/* Roles0 */
.role_boxes {
  margin: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.role_boxes input {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #aaaeb8;
  background-color: #fff;
  color: #444;
  font-size: 16px;
  outline: none;
  padding: 17px 28px;
}
.role_boxes svg {
  font-size: 25px;
  width: 30px;
  margin-left: 10px;
  color: #a8a8a8;
}

.summary-head {
  color: var(--color_p2);
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.summary-head span {
  margin-left: 20px;
}

.summary-info {
  margin-top: 30px;
}

.summary-name {
  color: #aeaab4;
  padding-right: 30px;
}

.photoUpload {
  width: 300px;
}
.charTable {
  width: 100%;
}
.charTable > table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.form_group {
  position: absolute;
  top: -10px;
  left: -130px;
  transform: rotate(-90deg) translate(-50%, -50%);
  width: 230px;
  z-index: -99;
}

.form_group p {
  color: #b6b6b6;
  font-size: 12px;
  margin: 0;
  background-color: white;
  padding: 0 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  margin: auto;
  width: fit-content;
}

.form_group img {
  width: 100%;
}

.summary-info.row div span {
  display: inline-block;
}

.summary-info.row div {
  margin-bottom: 16px;
}
.summary-info.row div span.titleSpan {
  margin-right: 26px;
  width: 76px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #aeaab4;
}

.summary-info.row div span.contentSpan {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #444;
}

#finput {
  padding-right: 20px;
  padding-top: 20px;
}

/* Influencer YT */
.channelContainer {
  width: 37rem;
  position: relative;
  margin: auto;
  border: 1px solid black;
  border-radius: 10px;
  padding: 2rem;
}

.new-style-channel-container {
  padding: 20px;
  background: #f8f3ff;
  border: 1px solid #dcdcdc;
  margin-top: 50px;
}

.editIcon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.latestVideos {
  padding: 8px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 13px;
  max-height: 24rem;

  overflow-y: scroll;
}

.new-style-latestVideos {
  max-height: 17rem;
  padding: 20px;
}

.latestVideos::-webkit-scrollbar {
  display: none;
}

.button-next-div {
  display: flex;
  justify-content: space-between;
}

.button-next {
  color: #202020;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 6px 25px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

/* Collab Details */

.collabContainer {
  position: relative;
  /* width: 35vw; */
  margin: 1rem auto;
  background-color: #f8f3ff;
  padding: 20px;
  border-radius: 10px;
}

.Slot-Container {
  position: relative;
  width: 32rem;
  margin: 1rem auto;
  background-color: #f8f3ff;
  padding: 30px;
  border-radius: 10px;
}

.new-style-slot-container {
  width: 40vw;
}

.collablist {
  padding-top: 1rem;
}

.new-style-collablist {
  padding-top: 0rem;
}

.collab-main {
  background-color: #ffffff;
  border-radius: 0px 8px;
}

.collab-box {
  display: flex;
  justify-content: space-around;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  color: #202020;
}
input.collab-type, input.collab-price{
  outline: none;
}
.collab-type {
  flex: 6;
  margin: 0;
  padding: 10px 20px;
  border: 0px;

}

.collab-price {
  flex: 2;
  margin: 0;
  border-left: 1px solid #dcdcdc;
  padding-left: 10px;
  width: 100px;
  border: 0px;
  border-left: 1px solid #dcdcdc;
}

.collab-button {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid #dcdcdc;
  padding: 10px 20px;
}

.collab-details-buttons {
  display: flex;
  justify-content: space-between;
}

.collab-button-main {
  margin-top: 5px;
}

.next {
  margin-top: 40px;
}

.verify-text {
  font-size: 24px;
  color: #030303;
  margin-top: 10px;
  border-top: 2px solid #e0e0e0;
  margin-left: 30px;
  padding: 20px 0px 0px 0px;
}

.searchList {
  /* width: 80vw; */

  display: flex;
  justify-content: center;
}

.border {
  border-top: 2px solid #e0e0e0;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 450px);
  gap: 20px;
}

.channel-verify-div {
  background: #f8f3ff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 10px 20px;
}

.list-item {
  list-style-type: none;
}
.channelItem {
  display: flex;
  align-items: flex-start;
}

.channel-name-search {
  font-size: 24px;
  font-weight: 600;
  color: #030303;
  margin: 0;
}

.sub-count-search {
  color: #606060;
  margin: 0;
}

.date-search {
  color: #606060;
  margin: 0;
}
.desc-search {
  color: #606060;
  height: 100px;
  margin: 0;
  margin-top: 10px;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
}

.actionButtons-button {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 5px 10px;
}

.card-search-confirm {
  width: 500px;
  height: auto;
  background: #f8f3ff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 20px;
}

.confirm-heading {
  font-size: 24px;
  font-weight: 600;
  color: #030303;
}

.confirm-buttons {
  display: flex;
  justify-content: flex-end;
}

.button-cancel {
  padding: 10px 20px;
  color: #202020;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
}

.button-confirm {
  padding: 10px 20px;
  color: #202020;
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
  background: #d7ffe0;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
}

.add-collab-title {
  font-weight: 400;
  font-size: 20px;
}

.add-collab-button {
  display: flex;
  justify-content: flex-end;
}

.collab-save {
  font-size: 14px;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: -20px;
}

.collab-input {
  border-radius: 0px 8px;
}

.collab-startDate {
  flex: 4;
  margin: 0;
  padding: 10px 20px;
  text-align: center;
}

.collab-endDate {
  flex: 4;
  margin: 0;
  border-left: 1px solid #dcdcdc;
  padding-left: 10px;
  text-align: center;
}

.slot-input {
  width: 30%;
  border: none;
  border-radius: 0px 0px 0px 8px;
  padding: 0px 20px;
}

.width-pc-and-mobile{
  width: 115px;
}

.slot-end-date {
  border-left: 1px solid #dcdcdc;
  border-radius: 0px 8px 0px 0px;
}
