@import url("https://fonts.cdnfonts.com/css/muli");


.padding-left-and-right{
   padding-left: 1.5rem;
   padding-right: 1.5rem;

}

.padding-4{
   padding: 1.5rem;
}

.width-modal{
   width: 25%;
}

.margin-left-desktop{
   margin-left: 1.5rem;
 }

* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

body {
   overflow-x: hidden;
}

a {
   text-decoration: none;
   color: inherit;
}

a:hover {
   color: inherit;
}

/* custom scrollbar */
::-webkit-scrollbar {
   width: 20px;
}

::-webkit-scrollbar-track {
   background-color: transparent;
}

::-webkit-scrollbar-thumb {
   background-color: #d6dee1;
   border-radius: 20px;
   border: 6px solid transparent;
   background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
   background-color: #a8bbbf;
}

.secondary-text {
   color: #b8b8b8;
   font-size: 14px;
   margin: 12px 0;
}

.card1 {
   padding: 40px 30px;
   border-radius: 8px;
   box-shadow: 0 0 8px 1px rgba(228, 228, 228, 0.5);
   background-color: #fff;
}

.row {
   margin: 0;
   padding: 0;
}

/* ---------- */
/* Login Page */
/* ---------- */

.PhoneInputInput {
   border: none;
}

.PhoneInputInput:focus {
   border: none;
   box-shadow: none;
   outline: none;
}

.email-text1 {
   display: block;
   font-size: 16px;
   font-weight: 600;
}

.email-text2 {
   font-size: 16px;
   font-weight: 800;
}

.email-text3 {
   font-size: 14px;
}

.login-container {
   height: 100vh;
   width: 100%;
   /* overflow: hidden; */
   font-size: 18px;
}

.left-side {
   padding: 0;
   background-color: var(--color_p1);
   position: relative;
}

.right-side {
   padding: 0;
   overflow-y: scroll;
   height: 100%;
}

.login-img {
   width: 100%;
   height: 100vh;
   object-fit: cover;
   object-position: center -90px;
   mix-blend-mode: soft-light;
}

.login-footer {
   position: absolute;
   bottom: 0;
   color: #fff;
   padding: 0 50px;
   margin-bottom: 50px;
}

.quote {
   font-size: 22px;
   font-weight: 200;
   margin-right: 20%;
}

.open-quote {
   font-size: 80px;
   position: absolute;
   top: -60px;
   height: 50px;
}

.close-quote {
   font-size: 80px;
   position: absolute;
   bottom: 20px;
   right: calc(20% + 20px);
   height: 50px;
}

.author {
   margin-top: 10px;
   font-size: 24px;
   text-transform: uppercase;
}

.top-left {
   position: absolute;
   top: 0;
   color: #fff;
   font-size: 22px;
   padding: 20px 50px;
   z-index: 99;
}

.three-dots {
   position: relative;
   top: 15px;
   height: 0;
   text-align: center;
   color: #fff;
   font-size: 10px;
}

.submit-btn {
   background-color: var(--color_p2);
   border: var(--color_p2) 2px solid;
   height: 50px;
   color: #fff;
}

.submit-btn:hover {
   background-color: #fff;
   border: var(--color_p2) 2px solid;
   color: var(--color_p2);

   /* background-color: var(--color_p1);
    border: var(--color_p1) 2px solid; */
}

.create-btn {
   background-color: #e543c3;
   border: #e543c3 2px solid;
   height: 50px;
   color: #fff;
   width: 100%;
   margin: 30px 0px;
   padding-left: 60px;
   padding-right: 60px;
   margin-left: 40px;
}

.create-btn:hover {
   background-color: #fff;
   border: #e543c3 2px solid;
   color: #e543c3;
}

.form-logo {
   width: 250px;
   margin-bottom: 30px;
}

.alternate-option {
   color: var(--color_p2);
   margin-top: 30px;
}

.alternate-option b,
.alternate-option u {
   cursor: pointer;
}

/* ----- */
/* WEB 1 */
/* ----- */

.web1 {
   height: 100vh;
   overflow: hidden;
   background-image: linear-gradient(119deg, #ff3cac, #784ba0 50%, #2b86c5);
   padding: 50px;
}

.web1 .row {
   height: calc(100vh - 100px);
   /* overflow-y: auto; */
}

.form-container {
   width: 60%;
}

.web1-signin {
   position: relative;
   padding: 30px;
   margin-right: 25px;
   background-color: #fff;
   height: calc(100vh - 100px);
   border-radius: 5px;
   text-align: center;
}

.web1-images {
   padding: 0px;
   margin-left: 25px;
   background-color: transparent;
   height: calc(100vh - 100px);
   overflow-y: scroll;
}

.web1-logo {
   width: 180px;
}

.web1-tagline {
   color: var(--color_p2);
   font-size: 33px;
   line-height: 40px;
   text-align: center;
}

.btn-talents {
   background-color: var(--color_p3);
   border: var(--color_p3) 2px solid;
   height: 50px;
   width: 75%;
   margin: auto;
   margin-bottom: 20px;
   color: #fff;
   box-shadow: 0 0 24px 0 rgba(70, 67, 116, 0.54);
   border-radius: 50px;
}

.btn-talents:hover {
   background-color: #fff;
   border: var(--color_p3) 2px solid;
   color: var(--color_p3);
}

.btn-seekers {
   background-color: var(--color_p2);
   border: var(--color_p2) 2px solid;
   height: 50px;
   width: 75%;
   margin: auto;
   color: #fff;
   box-shadow: 0 0 24px 0 rgba(70, 67, 116, 0.54);
   border-radius: 50px;
   margin-bottom: 20px;
}

.btn-seekers:hover {
   background-color: #fff;
   border: var(--color_p2) 2px solid;
   color: var(--color_p2);
}

.menu {
   position: absolute;
   top: 25px;
   cursor: pointer;
}

.Rectangle-1 {
   width: 28px;
   height: 2.5px;
   margin-top: 5px;
   background-color: #8443e5;
}

.Rectangle-2 {
   width: 18px;
   height: 2px;
   margin-top: 5px;
   background-color: #8443e5;
}

.Rectangle-3 {
   width: 14px;
   height: 2.5px;
   margin-top: 5px;
   background-color: #8443e5;
}

/*  */
.grid-container {
   display: flex;
   flex-wrap: wrap;
   padding: 0 4px;
}

.column {
   flex: 20%;
   max-width: 25%;
   padding-left: 10px;
}

.column img {
   margin-bottom: 10px;
   vertical-align: middle;
   width: 100%;
   opacity: 0.3;
   border-radius: 2px;
}

.web1-image {
   position: relative;
}

.web1-image:hover img {
   opacity: 1;
   transition: 0.3s;
}

.web1-image:hover .image_overlay {
   opacity: 1;
   transition: 0.3s;
}

.image_overlay {
   position: absolute;
   bottom: 10px;
   width: 100%;
   height: 100%;
   padding: 20px 15px;
   opacity: 0;
   color: #fff;
   background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.overlay-name {
   font-size: 20px;
}

.overlay-profession {
   font-size: 14px;
   font-weight: 200;
}

.invalid-otp {
   color: red;
   display: none;
}

.correct-otp {
   color: green;
   display: none;
}

/* TalentDashboard */
.loadmore {
   width: 100%;
   text-align: center;
   margin: 50px 0 10px;
}

.loadmore button {
   background-color: #f5f5f5;
   border-radius: 5px;
   color: var(--color_p2);
   padding: 15px 50px;
}

.loadmore button:hover {
   border: 2px solid var(--color_p2);
   color: var(--color_p2);
}

.talent-heading {
   margin-bottom: 20px;
   font-size: 20px;
   font-weight: 700;
}

/* Roles creation */
.flex-container {
   display: flex;
   height: calc(100vh - 100px);
}

.roleDetails {
   width: 413px;
   height: 302px;
   margin: 25px 22px 35px 35px;
   padding: 32px 0 0;
   border-radius: 6px;
   box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
   background-color: #fff;
}

.roleDetails .row {
   padding: 20px;
}

.posted {
   padding: 10px;
}

.roleUpdate {
   width: 100%;
   padding: 25px 30px;
}

.roleChild {
   /* min-height: calc(100% - 50px); */
   height: calc(100vh - 100px);
   overflow-y: scroll;
   height: 100%;
   padding: 0px 31px 20px 29px;
   border-radius: 6px;
   box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
   background-color: #fff;
   position: relative;
}

.fixedDiv {
   padding-top: 20px !important;
   position: sticky !important;
   top: 0 !important;
   background-color: #fff;
   z-index: 1;
}

.announcesvg {
   width: 70px;
   height: 70px;
   margin-left: 15px;
   padding: 0 0 0;
   background-color: #fff;
   color: #8443e5;
}

.count {
   width: 413px;
   height: 132px;
   margin: 20px 0 0;
   padding: 19px 183px 19px 24px;
   background-color: #f4f4f4;
}

.hr1 {
   width: 356px;
   height: 0;
   margin: 23px 28px 15px 29px;
   opacity: 0.37;
   border: solid 1px #bd9af1;
}

.date {
   width: 67px;
   height: 18px;
   margin: 0 5px 0 15px;

   font-size: 14px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   text-align: left;
   color: #444;
}

.date-value {
   width: 76px;
   height: 18px;
   margin: 0 72px 0 5px;

   font-size: 14px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   text-align: center;
   color: #8443e5;
}

.Location {
   width: 55px;
   height: 18px;
   margin: 0 6px 0 0;

   font-size: 14px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   text-align: left;
   color: #444;
}

.location-value {
   width: 74px;
   height: 18px;
   margin: 0 0 0 6px;

   font-size: 14px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   text-align: center;
   color: #8443e5;
}

.project-name {
   width: 223px;
   height: 25px;
   margin: 0 91px 7px 0px;

   font-size: 18px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.25;
   letter-spacing: normal;
   text-align: left;
   color: #6c54a6;
}

.project-desc {
   width: 223px;
   height: 32px;
   margin: 0 9px 7px 0px;

   font-size: 13px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.23;
   letter-spacing: normal;
   text-align: left;
   color: #444;
}

.role {
   width: 44px;
   height: 21px;
   margin: 0 73px 9px 0;
   padding: 20px;
   font-size: 17px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #6c54a6;
}

.characters {
   width: 87px;
   height: 21px;
   /* margin: 0 0 9px 60px; */
   /* padding: 20px; */
   font-size: 17px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #6c54a6;
}

.role-count {
   width: 44px;
   height: 46px;
   margin: 9px 73px 0 0;
   padding: 20px;
   font-size: 40px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #8748e5;
}

.character-count {
   width: 44px;
   height: 46px;
   margin: 9px 45px 0 73px;
   padding: 20px;
   font-size: 40px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #8748e5;
}

.charForm {
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.charList {
   padding: 15px;
   display: flex;
   align-items: center;
}

.charList div {
   margin-right: 20px;
   width: 123px;
   display: grid;
   place-items: center;
   font-size: 14px;
   border-radius: 6px;
   cursor: pointer;
   padding: 10px;
}

.hihlightedCharList {
   background-color: #8443e5;
   color: #fff;
}

/* Applicant Details */
.content-container {
   display: flex;
}

.projectInfo,
.projectTitle {
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
   left: 99px;
   text-align: left;
}

.date,
.location,
.projectInfo,
.projectTitle {
   text-align: left;
}

.projCont {
   width: 413px;
   height: fit-content;
   margin: 25px 22px 0 35px;
   /* padding: 32px 28px 19px 24px; */
   border-radius: 6px;
   box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
   background-color: #fff;
}

.pc_child {
   padding: 37px 28px 30px 24px;
}

img.promotion {
   width: 52.3px;
   height: 49.5px;
   object-fit: contain;
   margin-right: 17px;
}

.projectTitle {
   /* width: 223px; */
   overflow-wrap: break-word;
   font-size: 18px;
   font-weight: 400;
   line-height: 1.25;
   color: #6c54a6;
}

.postedOn,
.projectInfo {
   font-weight: 400;
   color: #444;
}

.projectInfo {
   width: 100%;
   font-size: 13px;
   line-height: 1.23;
   overflow-wrap: anywhere;
}

.Path-26 {
   margin: 15px 0;

   opacity: 0.37;
   border: 1px solid #bd9af1;
}

.date,
.location,
.locationName {
   height: 18px;
}

.date,
.location,
.locationName,
.postedOn {
   font-size: 14px;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
}

.postedOn {
   left: 24px;
}

.date {
   width: 76px;
   margin: 0;
   font-weight: 500;
   color: #8443e5;
}

.location {
   width: 55px;
   font-weight: 400;
   color: #444;
}

.locationName {
   width: 74px;
   font-weight: 500;
   text-align: center;
   color: #8443e5;
}

div.lastRow {
   position: relative;
   height: 114px;
   width: inherit;
   display: flex;
   padding: 19px 26px;
   justify-content: space-between;
   background-color: #f4f4f4;
}

div.lastRow>span {
   height: 21px;

   font-size: 17px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #6c54a6;
   position: relative;
}

div.lastRow>span::after {
   content: attr(number);
   position: absolute;
   width: 44px;
   height: 46px;

   font-size: 37px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #8748e5;
   left: 0;
   top: 23px;
}

div.lastRow .appliedBy,
div.lastRow .characters {
   width: 87px;
}

div.lastRow .roles {
   width: 44px;
}

/* Applicant Details/Details*/
.topNavbar span,
div.tableHeading span {
   font-size: 16px;
   line-height: 1.25;
   color: #6c54a6;
}

div.applicantDetails {
   position: relative;
   width: 866px;
   height: 595px;
   border-radius: 6px;
   box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
   background-color: #fff;
   top: 24px;
   padding-bottom: 22px;
   padding-left: 60px;
}

div.topNavbar {
   margin-top: 23.5px;

   display: flex;
   /* margin-left: 30px; */
}

/* 
.topNavbar::-webkit-scrollbar {
  display: none;
} */
.topNavbar span {
   cursor: pointer;
   height: 20px;
   display: block;
   position: relative;
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
   width: 200px;
}

.leadRoles span,
div.statusContainer>div span {
   font-size: 14px;
   line-height: 1.29;
   color: #303030;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
   text-align: center;
}

.topNavbar span[highlighted="true"] {
   font-weight: 700;
}

.topNavbar span[highlighted="true"]::after {
   content: "";
   position: absolute;
   left: 0;
   top: 24px;
   height: 4px;
   width: 100%;
   background-color: #6c54a6;
}

span.lead {
   min-width: 72px;
   margin-left: 66px;
}

span.supportingActor {
   margin-left: 103px;
   min-width: 164px;
}

span.chorus {
   margin-left: 54px;
   min-width: 164px;
}

.applicantDetails hr {
   position: absolute;
   top: 25px;
   left: 29px;
   height: 1px;
   background-color: #d8d8d8;
}

.applicantDetails .leadRoles {
   margin-top: 36.5px;
   /* margin-left: 52px; */
}

.leadRoles span {
   cursor: pointer;
   display: inline-block;
   width: fit-content;
   height: 18px;
   margin-right: 50px;
}

.leadRoles span[highlighted="true"] {
   height: 31px;
   padding: 6.5px 33px 6.5px 32px;
   border-radius: 6px;
   color: #fff;
   background-color: #8443e5;
}

div.statusContainer {
   display: flex;
   /* margin-left: 47px; */
}

div.statusContainer>div {
   cursor: pointer;
   position: relative;
   width: 241px;
   height: 74px;
   margin: 10px 10px;
   margin-left: 0;
   padding: 12.5px 17px 12px 19px;
   border-radius: 6px;
   border: 1px solid rgba(158, 158, 158, 0.5);
}

div.statusContainer>div span {
   margin-left: 3.1px;
}

div.statusContainer>div span::after {
   content: attr(number);
   position: absolute;
   right: 0;
   top: 26px;
   width: 34px;
   height: 36px;
   font-size: 29px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: center;
   color: #171717;
}

div.statusContainer>div[highlighted="true"] {
   border: 1px solid #e543c3;
}

div.statusContainer>div[highlighted="true"]>*,
div.statusContainer>div[highlighted="true"]>span::after {
   color: #e543c3;
}

div.applicantList {
   margin-top: 24.5px;
   position: relative;
   height: 345px;
   overflow-y: scroll;
}

div.tableHeading {
   /* margin-left: 61px; */
   display: flex;
   justify-content: flex-start;
}

div.tableHeading span {
   display: block;
   width: 33%;
   font-weight: 700;
   font-stretch: normal;
   font-style: normal;
   letter-spacing: normal;
}

/* div.tableHeading span.last {
  margin-left: 148px;
  margin-right: 157px;
} */
div.applicantList div.listItems {
   height: 322px;
}

div.applicantList hr {
   height: 1px;
   margin: 10px;
   background-color: #d8d8d8;
}

div.listItems {
   margin-top: 30px;
}

div.css-18dcxwc {
   overflow-y: scroll !important;
}

/* ------------------browse profile-------------------- */

.seeker-name-heading {
   width: 200px;
   padding-top: 10px;
}

.ad-type-heading {
   width: 200px;
   padding-top: 10px;
}

.price-heading {
   width: 200px;
   padding-top: 10px;
}

.date-heading {
   width: 250px;
   padding-top: 10px;
}

table {
   -moz-border-radius: 10px;
   -webkit-border-radius: 10px;
   border-radius: 12px;
   /* background: #ffffff; */
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
   border: 1px solid #dcdcdc;
   border-spacing: 0;
   border-collapse: separate;
   border-radius: 12px;
}

table th,
table td {
   border: 1px solid black;
}

td {
   padding: 5px 20px;
}

tr:nth-child(odd) {
   background-color: rgba(131, 48, 255, 0.06);
}

.b_table table {
   width: 100%;
}

.b_table table td {
   border: none;
   text-align: left;
}

.b_table thead td {
   /* font-family: Muli; */
   font-size: 16px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.25;
   letter-spacing: normal;
   text-align: center;
   color: #898989;
}

.b_table table tbody td {
   /* font-family: Muli; */
   font-size: 14px;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   text-align: center;
   color: #202020;
   text-align: left;
   color: #303030;
}

.slotID {
   width: 100px;
   padding-top: 10px;
   color: #898989;
   text-align: center;
}

.table-body-slotid {
   font-weight: 400;
   font-size: 14px;
   padding: 10px;
}

.table-body-name {
   font-size: 14px;
   font-weight: 600;
}

.b_table table tbody td button {
   /* width: 139px; */
   padding: 9px 5px 8px;
   border-radius: 8px;
   border: solid 1px #e543c3;
   background-color: #fff;
   font-size: 14px;
   font-weight: 600;
   color: #202020;
   position: relative;
}

.slot-id-button {
   border: 1px solid #dcdcdc;
}

.b_table tbody tr td {
   vertical-align: middle;
}

.table-body-button {
   display: flex;
   justify-content: center;
   align-items: flex-end;
}

.b_table tbody tr td img {
   width: 63px;
   height: 63px;
   margin-right: 5px;
}

.contactInfo {
   position: absolute;
   right: 30px;
   top: 130px;
   background-color: #ffffff;
   border: 1px solid red;
   width: auto;
   border: 1px solid #dcdcdc;
   border-radius: 8px;
}

.contact-details-div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #dcdcdc;
   padding: 8px;
}

.contact-details {
   margin: 0;
   font-size: 14px;
   font-weight: 600;
   color: #202020;
}

.up-arrow {
   width: 15px;
   height: 20px;
}

.info-details {
   display: flex;
   align-items: center;
   padding: 8px;
}

.info-text {
   margin: 0;
   font-size: 16px;
   font-weight: 600;
   color: #202020;
   padding: 0px 0px 0px 10px;
}

.user {
   width: 18px;
   margin: 0px 0px 0px 5px;
}

.phone {
   width: 22px;
   margin: 0px 0px 0px 5px;
}

.small_img figure img {
   width: 40.9px;
   height: 40.9px;
   /* margin: 3px; */
}

.userPfp {
   height: calc(100vh - 80px);
   z-index: 1;
}

.left_pfp {
   height: 100%;
}

.right_pfp {
   /* margin-left: 10px; */
   /* height: 100vh; */
   height: 100%;
}

.child_user {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
}

.right_pfp * {
   margin: 0;
   /* padding: 0; */
   box-sizing: border-box;
}

.p1 div h6 {
   /* font-family: Muli; */
   font-size: 16px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.25;
   letter-spacing: normal;
   text-align: left;
   color: #8443e5;
}

.p1 div p {
   /* font-family: Muli; */
   font-size: 14px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   text-align: left;
   color: #b8b8b8;
}

.p1 button {
   font-size: 13px;
   font-weight: 600;
   line-height: 1.27;
   text-align: center;
   color: #8443e5;
   width: 100px;
   height: 35px;
   margin: 5px 5px;
   /* padding: 5px 10px; */
   border-radius: 8px;
   border: solid 1px #8443e5;
   background-color: #fff;
}

.right_pfp hr {
   margin: 5px 0;
}

.horizontal_nav span {
   font-size: 15px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.27;
   letter-spacing: normal;
   text-align: center;
   color: #b8b8b8;
   cursor: pointer;
   padding-bottom: 5px;
}

.activeUser-class {
   color: #8443e5 !important;
   border-bottom: 2px solid #8443e5;
}

.horizontal_nav span:hover {
   color: #8443e5;
}

.purple_title {
   /* font-family: "Muli"; */
   /* width: 108px; */

   /* margin: 0 50px 13.5px 0; */
   font-size: 15px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.27;
   letter-spacing: normal;
   color: #6c54a6;
}

.ud_child {
   margin: 10px 0;
}

.ud_child p {
   font-size: 12px;
   color: #b8b8b8;
}

.ud_child h6 {
   font-size: 14px;
   color: #333;
}

.summaryChild {
   color: #444444;
}

.summaryChild h6,
.card-title {
   font-size: 14px;
   -webkit-text-stroke: 0.3px #444;
   color: #444444;
}

.summaryChild p {
   margin-top: 13px;
   height: 75px;
   overflow-y: scroll;
}

.summaryChild p::-webkit-scrollbar {
   display: none;
}

.summaryChild p,
.card-text {
   font-size: 12px;
   color: #444444;
   overflow-wrap: break-word;
}

.ud_child span button {
   outline: none;
   border: none;
   background-color: transparent;
}

.scroll_x {
   display: flex;
   flex-wrap: nowrap;
   overflow-x: auto;
}

.scroll_x .x_cards {
   flex: 0 0 auto;
   width: 300px;
   margin: 29px 18px 49px 13.3px;
   padding: 22px 32px 0px 23px;
   border-radius: 6px;
   border: solid 1px #d1d1d1;
   background-color: #fff;
}

.scroll_x::-webkit-scrollbar {
   display: none;
}

.userSub_modal {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 2;
   left: 0;
   top: 0;
   height: 100vh;
   width: 100vw;
}

.blur_black {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 2;
   left: 0;
   bottom: 0;
   height: calc(100vh - 80px);
   width: 100vw;
   z-index: -1;
}

.userSub_modal .modal_child {
   position: relative;
   background-color: #fff;
   width: 729px;
   height: 527px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}

.userSub_modal .modal_child figure img {
   width: 50px;
   height: 66.3px;
}

.userSub_modal p {
   font-size: 16px;
}

.userSub_modal .btns {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.userSub_modal .btns button:nth-child(1) {
   width: 132px;
   height: 45px;
   border-radius: 6px;
   background-color: #b8b8b8;
   margin-right: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   outline: none;
   border: none;
   color: #fff;
}

.userSub_modal .btns button:nth-child(2) {
   width: 132px;
   height: 45px;
   border-radius: 6px;
   background-color: #8443e5;
   display: flex;
   align-items: center;
   justify-content: center;
   outline: none;
   border: none;
   color: #fff;
}

.close_modal {
   position: absolute;
   right: 2%;
   top: 0%;
   border: none;
   outline: none;
   background-color: #fff;
   font-size: 30px;
}

.dim {
   background-color: #000;
   opacity: 0.3;
}

.sub_nav button {
   border: none;
   outline: none;
   background-color: #fff;
   padding: 2px 6px;
   border-radius: 5px;
   margin-right: 20px;
}

.sub_nav .active_btn {
   background-color: #8443e5;
   color: #fff;
}

/* =================================Audition======================================= */
.side_nav {
   /* position: fixed; */
   width: 273px;
   position: absolute;
   background-color: #402c87;
   min-height: 100%;
   padding: 30px 15px !important;
   display: flex;
   flex-direction: column;
}

.subAud {
   width: calc(100vw - 310px);
   margin-left: 300px;
}

.navSideToggle {
   display: none;
}

.side_nav span {
   color: #fff;
   height: 30px;
   /* padding: 10px 5px; */
}

.side_nav span a {
   /* width: 100px; */
   padding: 3px 0px;
}

.lactive-class {
   color: white;
   border-bottom: 2px solid #fff;
}

.aa_head p {
   width: 170px;
}

.experience_container {
   position: relative;
}

.experience_container {
   padding: 10px 40px;
}

.ec_child {
   display: flex;
   scroll-behavior: smooth;
   overflow-x: hidden;
   overflow-y: hidden;
   width: 100%;
}

.experience_container .ec_child div {
   min-width: 48.7% !important;
   width: 48.7% !important;
   min-width: 98%;
   width: 98%;
   border-radius: 6px;
   border: solid 1px #d1d1d1;
   padding: 10px;
   margin-right: 10px;
   height: 150px;

   /* width: 50% !important; */
}

.experience_container .controllers button:nth-child(1) {
   position: absolute;
   top: 50%;
   left: 0;
   transform: translate(0%, -50%);
   border: none;
   background-color: #fff;
   font-size: 20px;
   z-index: 3;
   cursor: default;
}

.experience_container .controllers button:nth-child(2) {
   position: absolute;
   top: 50%;
   right: 0;
   transform: translate(0%, -50%);
   border: none;
   background-color: #fff;
   cursor: default;
}

.experience_container .controllers button svg {
   position: relative;
   height: 100%;
   font-size: 30px;
   height: 100%;
   z-index: 0;
   cursor: pointer;
}

.experience_container .controllers button svg path {
   position: absolute;
}

.experience_container .controllers button svg:hover {
   color: #8443e5;
}

/* ==============================Notification============================== */
.n_icon {
   position: relative;
}

.notif-options {
   position: absolute;
   right: 0;
   top: 110%;
   width: 372px;
   /* max-height: 285px; */
   box-shadow: 0 8px 9px 0 rgba(160, 160, 160, 0.5);
   background-color: #fff;
   height: 0px;
   overflow-y: hidden;
   transition: all 0.5s ease-in;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.notif-options * {
   margin: 0;
}

.notif-options div {
   /* height: 100%; */
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.notif-options div div {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-direction: row;
   padding: 0px 27px;
}

.notif-options div div:hover {
   background-color: #f2edf8;
}

.notif-options div div img {
   height: 45px;
   width: 45px;
}

.notif-options div div p {
   font-size: 14px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.27;
   letter-spacing: normal;
   margin-left: 10px;
   color: #8443e5;
}

.notif-options .view_all {
   height: fit-content;
   padding: 7px;
}

.notif-options .view_all p {
   font-size: 13px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.27;
   letter-spacing: normal;
   text-align: left;
   color: #cbcbcb !important;
}

.notif-options .view_all:hover p {
   color: #8443e5 !important;
}

.notif-options hr {
   margin: 0 27px;
}

.notificationDiv img {
   height: 45px;
   width: 45px;
}

.next_bottom {
   /* width: 100%; */
   height: 75px;
   background-color: #f4f4f4;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.next_bottom div {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.next_bottom div * {
   margin: 0 10px;
}

.next_bottom img {
   width: 42.5px;
   height: 42.5px;
}

.many_images img {
   width: 20px;
   height: 27px;
   margin-right: 2px !important;
}

.many_images span {
   width: 28px;
   height: 27px;
   font-size: 15px;
   padding: 5px 2px;

   background-color: #d8d8d8;
}

/* =======================================My role page=============================== */
.role_main {
   height: calc(100vh-100px);
   width: 100%;
   padding: 30px 19px 61px 42px;
}

.role_main h1 {
   height: 23px;
   margin: 0px 120px 30px 0;
   font-size: 18px;
   font-weight: bold;
   color: #444;
}

.role_main .long_Cards {
   border-radius: 6px;
   margin-bottom: 25px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   /* width: calc(657px + 150px); */
   width: 100%;
   height: 160px;
   box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
}

.role_main .long_Cards .firstDiv {
   width: 270px;
   height: 160px;
   background-color: #8443e5;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}

.role_main .long_Cards div div {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   border-right: 1px solid #d8d8d8;
}

.role_main .long_Cards div div:last-child() {
   border: 0;
}

.role_main .long_Cards div div:nth-last-child(2) h3 {
   color: #f5a623;
}

.role_main .long_Cards div div:nth-last-child(1) h3 {
   color: #ff5a5a;
}

.role_main .long_Cards div span {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   height: 101px;
}

.role_main .long_Cards div span p {
   font-size: 14px;
   font-weight: bold;
   color: #b8b8b8;
   text-align: center;
   margin-bottom: 5px;
}

.role_main .long_Cards div span h3 {
   color: #8443e5;
   font-weight: 600;
}

.role_main .long_Cards .--2 {
   background-color: #e543c3;
}

.role_card * {
   margin: 0;
}

.role_card {
   width: 400px;
   height: 530px;
   /* background-color: #000; */
   border-radius: 6px;
   box-shadow: 0 0 6px 0 rgba(158, 158, 158, 0.5);
}

.role_card .rCard_detail {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.rCard_child {
   font-weight: bold;
}

.role_card .rCard_detail div {
   display: flex;
   justify-content: flex-start;
}

.hurrayDiv {
   margin-top: 40px;
}

.hurrayDiv img {
   width: 152px;
   height: 47px;
}

.rCard_child p {
   font-size: 16px;
   color: #8443e5;
}

.rCard_child button {
   width: 379px;
   height: 79px;
   font-size: 22px;
   background-color: #8748e5;
   color: #fff;
   border: none !important;
   border-radius: 6px;
   outline: none !important;
}

.rCard_detail p {
   font-size: 14px;
   font-weight: 600;
   color: #444;
   width: 100px;
}

.rCard_detail span {
   font-size: 16px;
   color: #8443e5;
   width: 100px;
}

.rCard_child h2 {
   font-size: 16px;
   font-weight: bold;
   color: #8443e5;
   text-decoration: underline;
}

.rDetails_bottom {
   padding: 20px 25px;
   width: 100%;
   height: 114px;
   background-color: #f4f4f4;
   border-radius: 6px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.rDetails_bottom img {
   width: 71px;
   height: 36px;
}

.rDetails_bottom p {
   font-size: 9px;
   color: #afafaf;
}

.rDetails_bottom h5 {
   font-size: 16px;
   color: #8443e5;
}

.rDetails_bottom h6 {
   font-size: 12px;
   color: #8443e5;
}

.role_main .dot {
   height: 12px;
   width: 12px;
   border-radius: 50%;
   border: 3px solid #8443e5;
   color: #8443e5;
   margin: 10px;
}

.role_main .dot_active {
   background-color: #8443e5;
   height: 16px;
   width: 16px;
}

/* ====================REquest Page============================= */
.requestPage {
   height: 100vh;
   width: 100vw;
}

.requestPage .b_table td {
   /* width: 200px; */
   text-align: start;
}

.show-mobile{
   display: none;
}

.show-mobile-btn{
   display: none;
}

/* .requestPage .b_table td {
  width: 150px;
} */

/* =============================================chat page===================================== */
.chat_page * {
   margin: 0;
}

.chat_page {
   padding: 33px 27px 24px 27px;
}

.myChat_main {
   display: block;
   box-shadow: 0 0 6px 0 rgba(180, 180, 180, 0.5);
   /* width: 292px; */
   /* height: 600px; */
   height: calc(100% - 70px) !important;
}

.chat_page h4 {
   font-size: 18px;
   font-weight: bold;
   color: #444444;
   padding-bottom: 5px;
}

.chat_page .userChat {
   cursor: pointer;
   display: flex;
   align-items: center;
   /* width: 292.9px; */
   /* width: 100%; */
   height: 69px;
   padding: 13px 9px 11px 9px;
}

.chat_page .userChat figure img {
   width: 43px;
   height: 43px;
   margin-right: 9px;
}

.userChat div h6 {
   font-size: 11px;
   color: #8443e5;
}

.userChat div p {
   font-size: 11px;
   /* width: 100%; */
   white-space: wrap;
}

.active_userChat {
   background-color: #6c54a6;
}

.active_userChat div h6 {
   color: #fff;
}

.active_userChat div p {
   color: #9996d2;
}

.myChat_users {
   /* width: 100%; */
   height: calc(100vh - 99px);
   overflow-y: scroll;
   margin: 0;
   padding: 0;
}

.user_unseenDiv {
   display: flex;
   width: 100%;
   justify-content: space-between;
}

.user_unseenDiv span {
   background-color: #8443e5;
   color: #fff;
   border-radius: 50%;
   height: 20px;
   width: 20px;
   font-size: 10px;
   display: grid;
   place-content: center;
}

.myChat_users::-webkit-scrollbar {
   display: none;
}

.myChat input {
   width: 292px;
   height: 59px;
   padding-left: 17%;
   background-color: #8443e5;
   outline: none;
   border: none;
   color: #fff;
   width: 100%;
}

.myChat input.blackPlaceHolder::placeholder {
   color: #fff;
   font-weight: 500;

}

.myChat input.blackPlaceHolder {
   padding-left: 5%;
}

.searchChat {
   position: relative;
}

.searchChat .search_icon {
   position: absolute;
   color: #fff;
   font-size: 20px;
   top: 50%;
   transform: translateY(-50%);
   left: 10%;
}

/* -------------------chat boc-------------- */
.chatBox_main {
   /* box-shadow: 0 0 6px 0 rgba(180, 180, 180, 0.5); */
   /* height: 600px; */
   height: calc(600px - 80px);
}

.cb_top {
   width: 100%;
   height: 59px;
   background-color: #6c54a6;
   display: flex;
   align-items: center;
   padding: 0 10px 0px 36px;
}

.cb_textDiv h6 {
   color: #fff;
   font-size: 12px;
   font-weight: bold;
}

.cb_textDiv p {
   color: #b8a3ec;
   font-size: 11px;
}



.cb_msgSend {
   background-color: #fff;
   width: 100%;
   height: 56px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 5px 10px;
   position: relative;
}

.cb_msgSend input {
   width: 100%;
   outline: none;
   border: none;
}

.cb_msgSend button {
   width: 120px;
   height: 42px;
   border-radius: 3px;
   background-color: #8443e5;
   color: #fff;
   border: none;
   outline: none;
}

.cb_chats_main {
   height: 100%;
}

.cb_chats_msg {
   height: fit-content;
   display: flex;
   margin: 10px 0;
}

.cb_chats_msg img {
   width: 43px;
   height: 43px;
   margin-right: 10px;
   background-color: #8443e5;
}

.cb_chats_msg p {
   background-color: #fff;
   padding: 10px 22px;
   width: 390px;
   position: relative;
   font-size: 14px;
   font-weight: bold;
   color: #000000;
   line-height: 20px;
   word-wrap: break-word;
}

.cb_chats_msg p::before {
   display: block;
   position: absolute;
   height: 0;
   width: 0;
   top: 0;
   left: -10px;
   border: 10px solid transparent;
   border-top-color: #fff;
}

.cb_chats_msg2 {
   height: fit-content;
   display: flex;
   justify-content: flex-end;
   margin: 10px 0;
   width: 99%;
}

.cb_chats_msg2 img {
   width: 43px;
   height: 43px;
   margin-left: 10px;
}

.cb_chats_msg2 p {
   background-color: #fff;
   padding: 10px 22px;
   width: 390px;
   position: relative;
   font-size: 14px;
   font-weight: bold;
   color: #888;
   line-height: 20px;
   word-wrap: break-word;
   position: relative;
}

.cb_chats_msg2 p svg {
   font-size: 20px;
   position: absolute;
   bottom: 0;
   right: 0;
   color: #afaaaa;
}

.cb_chats_msg2 p::before {
   content: "";
   display: block;
   position: absolute;
   height: 0;
   width: 0;
   top: 0;
   right: -10px;
   border: 10px solid transparent;
   border-top-color: #fff;
}

.msg_Div {
   height: 460px !important;
   overflow-y: scroll;
   overflow-x: hidden;
   scroll-behavior: smooth;
}

.dots_div {
   position: relative;
   top: 50%;
}

.dots_div div {
   position: absolute;
   width: 143px;
   right: 50%;
   bottom: -70%;
   z-index: 1;
   display: none;
}

.dots_div div ul {
   background-color: #6c54a6;
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   flex-direction: column;
   padding-left: 0;
}

.dots_div ul li {
   color: #fff;
   height: 100%;
   width: 100%;
   cursor: pointer;
   list-style: none;
   font-style: 11px;
   font-size: 14px;
   /* line-height: 1.27; */
   padding: 10px 5px 10px 5px;
   transition: all 0.1s;
}

.dots_div ul li:hover {
   background-color: #8d6fd1;
}

.searchedChat {
   background-color: #ebe6f8;
}

.searchedChat:hover {
   background-color: #c0abf5;
}

.picker-container {
   position: absolute;
   width: 100%;
   bottom: 100%;
   right: 1px;
}

aside.emoji-picker-react {
   width: 100% !important;
   transition: all 1s;
}

.emoji-picker-react .emoji button {
   width: 50px;
}

.emoji-icon {
   width: 30px;
   height: 30px;
   cursor: pointer;
}

.purple_head {
   font-size: 20px;
   color: #8443e5;
   margin: 20px 35px;
}

.bubbleDiv {
   position: relative;
}

.bubbleDiv h6 {
   position: absolute;
   margin: 0;
   top: 0;
   right: 0;
   background-color: #8443e5;
   display: grid;
   place-items: center;
   font-size: 10px;
   height: 20px;
   width: 20px;
   color: #fff;
   border-radius: 50%;
}

.bubbleColorChange h6 {
   background-color: #fff;
   color: #8443e5;
   box-shadow: 0 0 8px 1px rgba(140, 138, 138, 0.5);
}

.userImage_main {
   position: relative;
}

.userImage_main .display {
   width: 100%;
   height: 300px;
}

.imageUser {
   width: 100%;
   display: flex;
   justify-content: space-between;
   position: absolute;
   color: #fff;
   font-size: 40px;
   top: 50%;
   opacity: 0.7;
}

.side_toggle_chat {
   display: block;
   height: calc(100% - 80px) !important;
}

.side_chat {
   display: none !important;
   transition: all 0.3s;
}

.filter :last-child {
   outline: none;
}

.faq_accordion li {
   list-style: none;
}

.faq_accordion .question {
   cursor: pointer;
}

.faq_accordion h1 {
   margin-top: 20px;
   color: #8443e5;
}

.faq_accordion .question h2 {
   font-size: 17px;
   line-height: 34px;
   font-weight: 300;
   letter-spacing: 1px;
   display: block;
   background-color: #fefffa;
   margin: 0;
   transition: all 0.25s ease-in-out;
   color: #444;
   font-weight: 600;
}

.faq_accordion p {
   color: rgba(48, 69, 92, 0.8);
   font-size: 14px;
   line-height: 26px;
   letter-spacing: 1px;
   position: relative;
   overflow: hidden;
   max-height: 800px;
   opacity: 1;
   transform: translate(0, 0);
   margin-top: 0px;
   z-index: 2;
   transition: all 0.25s ease-in-out;
}

.accordion_left {
   position: relative;
}

.accordion_left div {
   position: sticky;
   top: 100px;
}

.faq_accordion ul {
   position: sticky;
   top: 100px;
}

.faq_accordion ul {
   padding: 0 !important;
}

.accordion_left h5 {
   font-size: 20px;
   cursor: pointer;
}

.accordion_left h5:hover {
   color: #8443e5;
}

/* Browse Influencer */

div.bookingBox {
   padding: 6px;
}

.slotBox {
   margin-top: 2rem;
}

.slotBox div {
   display: inline-block;
   width: fit-content;
   border-radius: 5px;
   padding: 5px;
   background-color: grey;
   cursor: not-allowed;
   border: 1px solid #dcdcdc;
   border-radius: 0px 8px;
   margin: 5px 10px;
}

.slotBox .available {
   cursor: pointer;
   background-color: white;
}

.slot-start-end {
   font-size: 14px;
   font-weight: 400;
}

.slotBox .activeSlot {
   background-color: #8443e5;
   color: white;
}

.collabBox {
   margin-top: 2rem;
}

/* .collabBox div {
  display: flex;
  align-items: center;
} */
.collabBox div label {
   cursor: pointer;
}

input[type="radio"] {
   height: fit-content;
   margin-right: 5px;
}

input[type="checkbox"] {
   height: fit-content;
   margin-right: 5px;
}

.labelItem {
   display: flex;
   justify-content: space-between;
   background-color: white;
   border-radius: 5px;
   width: 8rem;
   margin: 10px 0px;
   padding: 5px 2px;
   cursor: pointer;
}

.active-labelItem {
   background-color: #bb8eff;
   padding: 5px 2px;
   border-radius: 0px 8px;
   color: #ffffff;
}

.section-youtube-info {
   display: flex;
   justify-content: center;
   align-items: center;
}

.influencers {
   padding-right: 20px;
   margin-right: 20px;
   border-right: 2px solid #e0e0e0;
}

.new-style-influencers {
   height: 80vh;
   overflow: scroll;
}

/* .new-style-influencers::-webkit-scrollbar {
  display: none;
} */

.card-influencer {
   background-color: red;
   padding: 10px;
   margin: 10px 0px;
   background: #f8f3ff;
   border: 1px solid #dcdcdc;
   border-radius: 10px;
}

.slotBook {
   background-color: aqua;
   width: 35%;
   height: auto;
   background: #f8f3ff;
   border: 1px solid #dcdcdc;
   border-radius: 10px;
   margin-top: 10px;
}

.fixed-slotbook {
   /* position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  border: 3px solid #73ad21; */
}

.empty-div {
   display: flex;
   align-items: center;
   height: 80vh;
   text-align: center;
}

.empty-title {
   font-size: 24px;
   font-weight: 400;
   color: #030303;
   font-family: "inter";
   opacity: 0.6;
}

.empty-text {
   font-family: "inter";
   font-weight: 400;
   font-size: 16px;
   color: #000000;
}

.booking-slot-details {
   display: flex;
   justify-content: center;
}

.slotBox-title {
   font-weight: 500;
   font-size: 20px;
   color: #030303;
   margin: 0;
   margin-bottom: 10px;
}

.ad-type-slot {
   display: grid;
   grid-template-columns: 1fr 1fr;
}

.slotBox-buttons {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin-bottom: 20px;
}

.slot-buttons-book {
   background: #ffffff;
   border: 1px solid #dcdcdc;
   border-radius: 8px;
   padding: 10px 20px;
}

.modal-main {
   width: fit-content;
   position: absolute;
   right: 600px;
   top: 200px;
   padding: 15px;
   background: #ffffff;
}

.buy-confirm {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   width: 25vw;
}

.buy-title {
   font-size: 16px;
   font-weight: 500;
   color: #202020;
   margin: 0;
}

.slot-text {
   font-size: 13px;
   font-weight: 400;
   color: rgba(3, 3, 3, 0.6);
   margin: 0;
}

.slot-start-end-date {
   background: rgba(112, 0, 255, 0.7);
   border-radius: 0px 8px;
   padding: 10px;
   width: 220px;
   color: #ffffff;
   text-align: center;
}

.order {
   font-size: 15px;
   font-weight: 500;
   color: #212529;
   border-top: 1px solid #dcdcdc;
   border-bottom: 1px solid #dcdcdc;
   padding: 5px 0px;
}

.buy-content {
   display: flex;
   justify-content: space-between;
}

.buy-thing {
   font-size: 13px;
   font-weight: 400;
   color: #212529;
}

.buy-price {
   font-size: 16px;
   font-weight: 600;
   color: #212529;
}

.buy-total {
   display: flex;
   justify-content: space-between;
   border-top: 1px solid #dcdcdc;
   border-bottom: 1px solid #dcdcdc;
   padding: 5px 0px;
}

.total-text {
   font-size: 16px;
   font-weight: 600;
   color: #212529;
   margin: 0;
}

.total-price {
   font-size: 18px;
   font-weight: 600;
   color: #212529;
   margin: 0;
}

.div-pay {
   display: flex;
   justify-content: space-between;
}

.button-pay {
   font-weight: 500;
   font-size: 14px;
   color: #202020;
   background: #d7ffe0;
   border: 1px solid #c7c7c7;
   border-radius: 8px;
   padding: 10px 20px;
   margin: 10px 0px;
}

.active-class {
   border: 1px solid #dcdcdc;
   box-shadow: 5px 3px 10px rgb(0 0 0 / 0.2);
}

.innox {
   margin: 10px 0px 20px 0px;
}

/* myroles card prev next button */

div.prevNextBtn {
   /* position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10%;
  bottom: -6px;
  z-index: -1; */
}

div.prevNextBtn button {
   width: 50px;
   height: 50px;
   font-size: 20px;
   background: transparent;
   outline: none;
   border: none;
}

/* Loader */
.lds-ellipsis {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 100%;
}

.lds-ellipsis div {
   position: absolute;
   top: 10px;
   width: 13px;
   height: 13px;
   border-radius: 50%;
   background: #fff;
   animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.btn-loader:hover .lds-ellipsis div {
   background: #8443e5;
}

.lds-ellipsis div:nth-child(1) {
   left: 8px;
   animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
   left: 8px;
   animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
   left: 32px;
   animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
   left: 56px;
   animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
   0% {
      transform: scale(0);
   }

   100% {
      transform: scale(1);
   }
}

@keyframes lds-ellipsis3 {
   0% {
      transform: scale(1);
   }

   100% {
      transform: scale(0);
   }
}

@keyframes lds-ellipsis2 {
   0% {
      transform: translate(0, 0);
   }

   100% {
      transform: translate(24px, 0);
   }
}


/* My applications */

.myapplications table thead td {
   text-align: left;
}


/* changes by arun */

.menu-project-creation {
   /* margin-top: 100px; */
   padding: 10px;
   height: 100px;
   display: none;
}

.showinpc {
   display: block
}

.mobile-only {
   display: none;
}

.buttons {
   display: flex;
   flex-direction: row;
}

.setting-textfield {
   margin-right: 4px;
}

.account-main-div {
   width: 80%
}

.nav-item-name {
   display: none;
}

.size-device{
   width: 30px;
   height: 30px;
}

.strength-main-div {
   display: none;
}